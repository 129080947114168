import { IconColorProps } from './icons.type';

export function OrcID({ className, color = '#000', alt }: IconColorProps) {
  return (
    <svg
      className={className}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        d="M12.0625 7.34922H10.2109V13.5512H12.125C14.8516 13.5512 15.4766 11.4811 15.4766 10.45C15.4766 8.771 14.4065 7.34922 12.0625 7.34922ZM10.5 0.0834961C4.97581 0.0834961 0.5 4.5593 0.5 10.0835C0.5 15.6077 4.97581 20.0835 10.5 20.0835C16.0242 20.0835 20.5 15.6077 20.5 10.0835C20.5 4.5593 16.0242 0.0834961 10.5 0.0834961ZM7.24234 14.6303H6.03911V6.26333H7.24234V14.6303ZM6.64073 5.31011C6.48465 5.31011 6.33209 5.26383 6.20232 5.17712C6.07255 5.09041 5.97141 4.96717 5.91168 4.82298C5.85195 4.67878 5.83633 4.52012 5.86678 4.36705C5.89722 4.21397 5.97238 4.07337 6.08274 3.96301C6.1931 3.85265 6.3337 3.77749 6.48678 3.74705C6.63985 3.7166 6.79851 3.73222 6.94271 3.79195C7.0869 3.85168 7.21014 3.95282 7.29685 4.08259C7.38356 4.21236 7.42984 4.36492 7.42984 4.521C7.42909 4.73005 7.34572 4.93034 7.19789 5.07816C7.05007 5.22599 6.84978 5.30936 6.64073 5.31011ZM12.2742 14.6399H9.00806V6.26333H12.2581C15.352 6.26333 16.7113 8.47422 16.7113 10.4508C16.7097 12.5992 15.0315 14.6399 12.2742 14.6399Z"
        fill={color}
      />
    </svg>
  );
}
