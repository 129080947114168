import { IconColorProps } from './icons.type';

export function Info({ color = '#000', className, alt }: IconColorProps) {
  return (
    <svg
      className={className}
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <circle
        cx="5.5"
        cy="5"
        r="4.58333"
        stroke={color}
        strokeWidth="0.833333"
      />
      <path
        d="M5.50427 3.71C5.33427 3.71 5.19177 3.655 5.07677 3.545C4.96677 3.43 4.91177 3.2875 4.91177 3.1175C4.91177 2.9425 4.96677 2.8 5.07677 2.69C5.18677 2.58 5.32927 2.525 5.50427 2.525C5.67927 2.525 5.82177 2.58 5.93177 2.69C6.04177 2.8 6.09677 2.9425 6.09677 3.1175C6.09677 3.2875 6.03927 3.43 5.92427 3.545C5.81427 3.655 5.67427 3.71 5.50427 3.71ZM5.05427 8C5.02927 8 5.00677 7.9925 4.98677 7.9775C4.97177 7.9575 4.96427 7.935 4.96427 7.91V4.2275C4.96427 4.2025 4.97177 4.1825 4.98677 4.1675C5.00677 4.1475 5.02927 4.1375 5.05427 4.1375H5.93177C5.95677 4.1375 5.97677 4.1475 5.99177 4.1675C6.01177 4.1825 6.02177 4.2025 6.02177 4.2275V7.91C6.02177 7.935 6.01177 7.9575 5.99177 7.9775C5.97677 7.9925 5.95677 8 5.93177 8H5.05427Z"
        fill={color}
      />
    </svg>
  );
}
