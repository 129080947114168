import { IconProps } from './icons.type';

export function SampleData({ className, alt }: IconProps) {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 11.75C2.25 11.3358 2.58579 11 3 11H4.25C4.66421 11 5 11.3358 5 11.75V13C5 13.4142 4.66421 13.75 4.25 13.75H3C2.58579 13.75 2.25 13.4142 2.25 13V11.75ZM8.25 17.75C8.25 17.3358 8.58579 17 9 17H10.25C10.6642 17 11 17.3358 11 17.75V19C11 19.4142 10.6642 19.75 10.25 19.75H9C8.58579 19.75 8.25 19.4142 8.25 19V17.75ZM3 17C2.58579 17 2.25 17.3358 2.25 17.75V19C2.25 19.4142 2.58579 19.75 3 19.75H4.25C4.66421 19.75 5 19.4142 5 19V17.75C5 17.3358 4.66421 17 4.25 17H3Z"
        fill="#68C8FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3C2.5 2.58579 2.83579 2.25 3.25 2.25H8.58325H8.58333H13.9166H13.9167H19.2501C19.6643 2.25 20.0001 2.58579 20.0001 3V8.33333V13.6667V13.6667V19C20.0001 19.4142 19.6643 19.75 19.2501 19.75H13.9167C13.5025 19.75 13.1667 19.4142 13.1667 19V14.4167H8.58325C8.16904 14.4167 7.83325 14.0809 7.83325 13.6667V9.08333H3.25C2.83579 9.08333 2.5 8.74755 2.5 8.33333V3ZM18.5001 12.9167H14.6667V9.08334H18.5001V12.9167ZM14.6667 14.4167V18.25H18.5001V14.4167H14.6667ZM4 3.75H7.83325V7.58333H4V3.75ZM9.33333 3.75V7.58333H13.1666V3.75H9.33333ZM14.6667 7.58333V3.75H18.5001V7.58333H14.6667ZM9.33325 9.08334V12.9167H13.1666V9.08334H9.33325Z"
        fill="#68C8FF"
      />
    </svg>
  );
}
