import { IconProps } from './icons.type';

export function Quotes({ className, alt }: IconProps) {
  return (
    <svg
      className={className}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        d="M0 2.87379C0 1.28664 1.28915 0 2.8794 0C4.46966 0 5.75881 1.28664 5.75881 2.87379C5.75881 3.66487 5.49193 4.71887 5.00777 5.71289C4.32106 7.12274 2.12167 9.5475 2.12167 9.5475L0.909286 8.94131C0.909286 8.94131 2.57631 5.91036 2.43064 5.71289C1.05353 5.4978 0 4.30859 0 2.87379Z"
        fill="black"
      />
      <path
        d="M6.97119 2.87379C6.97119 1.28664 8.26034 0 9.8506 0C11.4408 0 12.73 1.28664 12.73 2.87379C12.73 3.66487 12.4631 4.71887 11.979 5.71289C11.2923 7.12274 9.09286 9.5475 9.09286 9.5475L7.88048 8.94131C7.88048 8.94131 9.5475 5.91036 9.40183 5.71289C8.02472 5.4978 6.97119 4.30859 6.97119 2.87379Z"
        fill="black"
      />
    </svg>
  );
}
