import { IconProps } from './icons.type';

export function FileWriter({ className, alt }: IconProps) {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61636 9.44245C4.4966 9.56221 4.42161 9.71917 4.40336 9.88741L4.04214 13.1409C4.01895 13.3506 4.08622 13.5603 4.22699 13.7175C4.36765 13.8747 4.56873 13.9646 4.77975 13.9646C4.80704 13.9646 4.83437 13.9631 4.86146 13.9601L8.11505 13.5989C8.28321 13.5806 8.44012 13.5056 8.55983 13.3859L16.8875 5.05418C17.2175 4.72298 17.4028 4.27446 17.4028 3.80677C17.4028 3.38513 17.2521 2.97896 16.9805 2.66038L16.984 2.656L15.4404 1.11236C15.1091 0.782508 14.6606 0.597168 14.1931 0.597168C13.7256 0.597168 13.2768 0.782718 12.9455 1.1126L4.61636 9.44245ZM15.8372 4.00523L15.8346 4.00784L14.3461 5.49637L12.5036 3.65394L13.9948 2.16336C14.1044 2.05414 14.2818 2.05418 14.3915 2.16337L15.8368 3.60929C15.946 3.71861 15.9462 3.89562 15.8372 4.00523ZM11.4544 4.70289L13.2969 6.54536L7.69266 12.1501L5.61975 12.38L5.84969 10.3076L11.4544 4.70289ZM1.15965 1.74828C0.79953 2.10854 0.597168 2.59699 0.597168 3.10647V15.4821C0.597168 15.9915 0.799531 16.48 1.15965 16.8402C1.51991 17.2004 2.0084 17.4028 2.51789 17.4028H14.8935C15.403 17.4028 15.8914 17.2004 16.2517 16.8403C16.6118 16.48 16.8142 15.9915 16.8142 15.4821V10.1782C16.8142 9.98146 16.736 9.79263 16.5969 9.65347C16.4577 9.51431 16.2689 9.43614 16.0721 9.43614C15.8753 9.43614 15.6865 9.51431 15.5473 9.65347C15.4082 9.79263 15.33 9.98146 15.33 10.1782V15.4821C15.33 15.5978 15.284 15.7089 15.2022 15.7908C15.1203 15.8726 15.0092 15.9186 14.8935 15.9186H2.51789C2.40211 15.9186 2.29102 15.8726 2.20919 15.7908C2.12736 15.7089 2.08135 15.5978 2.08135 15.4821V3.10647C2.08135 2.9907 2.12736 2.8796 2.20919 2.79777C2.29102 2.71594 2.40211 2.66994 2.51789 2.66994H7.82171C8.08688 2.66994 8.33187 2.52848 8.4644 2.29888C8.59692 2.06929 8.59692 1.78639 8.4644 1.55681C8.33187 1.32721 8.08688 1.18575 7.82171 1.18575H2.51789C2.0084 1.18575 1.51992 1.38816 1.15965 1.74828Z"
        fill="#68C8FF"
      />
    </svg>
  );
}
