import { IconProps } from './icons.type';

export function Newest({ className, alt }: IconProps) {
  return (
    <svg
      className={className}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9928 6.98575L10.4065 8.39543C10.1617 8.98403 9.60812 9.3862 8.97268 9.43714L7.45081 9.55915L8.61031 10.5524C9.09445 10.9671 9.30588 11.6178 9.15797 12.2379L8.80372 13.723L10.1067 12.9272C10.6507 12.5949 11.3349 12.5949 11.8789 12.9272L13.1819 13.723L12.8276 12.2379C12.6797 11.6178 12.8911 10.9671 13.3753 10.5524L14.5348 9.55915L13.0129 9.43714C12.3775 9.3862 11.8239 8.98403 11.5791 8.39543L10.9928 6.98575ZM11.9161 5.42993C11.5745 4.60864 10.4111 4.60864 10.0695 5.42993L9.06765 7.83858C9.03165 7.92514 8.95025 7.98428 8.8568 7.99178L6.25646 8.20024C5.36981 8.27133 5.01028 9.37783 5.68582 9.9565L7.667 11.6536C7.7382 11.7146 7.76929 11.8103 7.74754 11.9015L7.14226 14.439C6.93587 15.3042 7.87712 15.988 8.63622 15.5244L10.8625 14.1646C10.9425 14.1157 11.0431 14.1157 11.1231 14.1646L13.3494 15.5244C14.1085 15.988 15.0497 15.3042 14.8433 14.439L14.238 11.9015C14.2163 11.8103 14.2474 11.7146 14.3186 11.6536L16.2998 9.9565C16.9753 9.37783 16.6158 8.27133 15.7291 8.20024L13.1288 7.99178C13.0353 7.98428 12.9539 7.92514 12.9179 7.83858L11.9161 5.42993Z"
        fill="#68C8FF"
      />
      <path
        d="M10.2678 0.934961C10.2678 0.534555 10.5924 0.209961 10.9928 0.209961C11.3932 0.209961 11.7178 0.534555 11.7178 0.934961V2.48496C11.7178 2.88537 11.3932 3.20996 10.9928 3.20996C10.5924 3.20996 10.2678 2.88537 10.2678 2.48496V0.934961Z"
        fill="#68C8FF"
      />
      <path
        d="M20.4007 9.2688C20.8011 9.2688 21.1257 9.59339 21.1257 9.9938C21.1257 10.3942 20.8011 10.7188 20.4007 10.7188H18.8507C18.4503 10.7188 18.1257 10.3942 18.1257 9.9938C18.1257 9.59339 18.4503 9.2688 18.8507 9.2688H20.4007Z"
        fill="#68C8FF"
      />
      <path
        d="M17.2242 2.73287C17.5073 2.44974 17.9663 2.44974 18.2495 2.73287C18.5326 3.016 18.5326 3.47504 18.2495 3.75817L17.1535 4.85419C16.8703 5.13732 16.4113 5.13732 16.1281 4.85419C15.845 4.57106 15.845 4.11201 16.1281 3.82888L17.2242 2.73287Z"
        fill="#68C8FF"
      />
      <path
        d="M19.0066 16.2322C19.2898 16.5154 19.2898 16.9744 19.0066 17.2575C18.7235 17.5407 18.2645 17.5407 17.9813 17.2575L16.8853 16.1615C16.6022 15.8784 16.6022 15.4193 16.8853 15.1362C17.1684 14.8531 17.6275 14.8531 17.9106 15.1362L19.0066 16.2322Z"
        fill="#68C8FF"
      />
      <path
        d="M10.2678 17.5026C10.2678 17.1022 10.5924 16.7776 10.9928 16.7776C11.3932 16.7776 11.7178 17.1022 11.7178 17.5026V19.0526C11.7178 19.453 11.3932 19.7776 10.9928 19.7776C10.5924 19.7776 10.2678 19.453 10.2678 19.0526V17.5026Z"
        fill="#68C8FF"
      />
      <path
        d="M3.13486 9.2688C3.53527 9.2688 3.85986 9.59339 3.85986 9.9938C3.85986 10.3942 3.53527 10.7188 3.13486 10.7188H1.58486C1.18446 10.7188 0.859863 10.3942 0.859863 9.9938C0.859863 9.59339 1.18446 9.2688 1.58486 9.2688H3.13486Z"
        fill="#68C8FF"
      />
      <path
        d="M4.0775 15.1362C4.36063 14.8531 4.81967 14.8531 5.1028 15.1362C5.38593 15.4193 5.38593 15.8784 5.1028 16.1615L4.00679 17.2575C3.72366 17.5406 3.26461 17.5406 2.98148 17.2575C2.69835 16.9744 2.69835 16.5153 2.98148 16.2322L4.0775 15.1362Z"
        fill="#68C8FF"
      />
      <path
        d="M5.85661 3.82878C6.13974 4.11191 6.13974 4.57095 5.85661 4.85408C5.57348 5.13721 5.11444 5.13721 4.83131 4.85408L3.73529 3.75807C3.45216 3.47494 3.45216 3.01589 3.73529 2.73276C4.01842 2.44963 4.47746 2.44963 4.7606 2.73276L5.85661 3.82878Z"
        fill="#68C8FF"
      />
    </svg>
  );
}
